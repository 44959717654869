import { getEnv, resolve } from 'core/utils/env';

import { dark, darkMobile, light, lightMobile } from 'site/themes';

const host = 'indicator.ru';
const siteUrl = 'https://' + host;

/**
 * Этот конфиг вынесен в отдельный файл чтоб шарить конфигурацию сайта
 * с галереей компонентов.
 * Сюда ни в коем случае не должно добавляться что-то кроме конфигурации
 * компонента App.
 */
export default function resolveConfig() {
  return {
    siteName: 'indicator',
    host: resolve({
      '*': host,
      'staging': getEnv('SITE_HOST'),
    }),
    theme: 'dark',
    themes: {
      light: {
        base: light,
        mobile: lightMobile,
      },
      dark: {
        base: dark,
        mobile: darkMobile,
      },
    },

    coreApisConfig: {
      bebopApi: resolve({
        'dev': '//s1.indicator.ru/api/v2',
      }),
    },

    webdavHost: resolve({
      '*': getEnv('WEBDAV_HOST'),
      'dev': '//wcdn.stage.unity.rambler.ru/indicator',
    }),

    thumborHost: resolve({
      '*': getEnv('THUMBOR_HOST'),
      'dev': '//s1.thumbor.unity.rambler.tech/unsafe',
    }),

    erratumUrl: resolve({
      '*': `${siteUrl}/erratum`,
      'staging': `https://${getEnv('SITE_HOST')}/erratum`,
    }),

    schemaOrg: {
      organizationName: 'Индикатор',
      /**
       * Логотип должен быть прямоугольным и лежать в пределах 60x600px:
       * https://developers.google.com/search/docs/data-types/article#logo-guidelines
       */
      organizationLogoUrl: siteUrl + '/icons/publisher-logo.png',
      organizationLogoWidth: 315,
      organizationLogoHeight: 60,
    },

    isUnityProject: false,

    defaultSiteMeta: {
      titleTemplate: '%s - Индикатор',
      defaultTitle: 'Новости науки и техники',
      meta: [
        { name: 'description', content: 'Indicator.ru - последние новости об открытиях российских и зарубежных ученых, острые дискуссии об организации науки в России и взаимодействии науки и бизнеса, собственные рейтинги российских ученых, научных организаций и инновационных компаний.' },
      ],
    },

    ramblerCommentsAppId: resolve({
      'production': 75,
      '*': 198,
    }),

    ads: {
      siteId: '435025376',
    },

    features: {
      enableRetina: true,
      disableBlurPreview: true,
    },

    pushWoosh: {
      applicationCode: resolve({
        '*': 'B0072-E87FE',
        'staging': '0894A-5AA48',
      }),
      safariWebsitePushID: 'web.indicator.ru',
      defaultNotificationTitle: '',
      defaultNotificationImage: siteUrl + '/icons/notification-logo.png',
    },

    specialLinks: [
      '/exports/rss',
      '/about', // чтобы работала хеш навигация
      '/earth-science/ne-gruzi-prirodu.htm',
      '/medicine/bolshie-vyzovy-starenie-naseleniya-i-novye-bolezni.htm',
      '/earth-science/kak-nam-osvoit-rossiyu.htm',
      '/medicine/rezhim-pitaniya.htm',
      '/mathematics/progress-v-zashite.htm',
      '/chemistry-and-materials/krov-ekonomiki.htm',
      '/engineering-science/tekhnologicheskii-perevorot.htm',
    ],

    counterIds: {
      yandexMetrika: 38745025,
      top100: 4437400,
      tns: { account: 'rambler_ru', tmsec: 'indicator_total' },
      mailru: 3090307,
      rcm: resolve({
        '*': 'RCM-60B2',
        'production': 'RCM-A676',
      }),
    },

    ramblerId: {
      rname: 'indicator',
      useAuthRedirect: true,
    },

    socials: [
      { name: 'vkontakte', link: 'https://vk.com/indicator_ru' },
      { name: 'odnoklassniki', link: 'https://ok.ru/group/54210128707685' },
      { name: 'telegram', link: 'https://telegram.me/indicator_news' },
    ],
  };
}
